import { DeviceKey } from './types'
declare global {
  interface Window {
    tizenSupportedKeys?: DeviceKey[]
  }
}
const defaultSupportedKeys: DeviceKey[] = [
  // 10009 is the back key
  {
    name: 'Enter',
    code: 13,
  },
  {
    name: 'Escape',
    code: 10009,
  },
  // 10009 is the back key
  {
    name: 'Escape',
    code: 27,
  },
  // ArrowLeft: 37
  {
    name: 'ArrowLeft',
    code: 37,
  },
  // ArrowUp: 38
  {
    name: 'ArrowUp',
    code: 38,
  },
  // ArrowRight: 39
  {
    name: 'ArrowRight',
    code: 39,
  },
  // ArrowDown: 40
  {
    name: 'ArrowDown',
    code: 40,
  },
  // F1 (Red): 403
  {
    name: 'F1',
    code: 403,
  },
  // F2 (Green): 404
  {
    name: 'F2',
    code: 404,
  },
  // F3 (Yellow): 405
  {
    name: 'F3',
    code: 405,
  },
  // F4 (Blue): 406
  {
    name: 'F4',
    code: 406,
  },
  // MediaPlay: 415
  {
    name: 'MediaPlay',
    code: 415,
  },
  // MediaPause: 19
  {
    name: 'MediaPause',
    code: 19,
  },
  // MediaPlayPause: 10252
  {
    name: 'MediaPlayPause',
    code: 10252,
  },
  // MediaRewind: 412
  {
    name: 'MediaRewind',
    code: 412,
  },
  // MediaFastForward: 417
  {
    name: 'MediaFastForward',
    code: 417,
  },
]

export const registerTizenKeys = async (): Promise<DeviceKey[]> => {
  if (typeof window === 'undefined') {
    return defaultSupportedKeys
  }
  if (typeof window?.tizenSupportedKeys !== 'undefined') {
    return window.tizenSupportedKeys
  }
  window.tizenSupportedKeys = defaultSupportedKeys
  try {
    const tizenCore = await import('tizen-common-web')
    if (!tizenCore.tvinputdevice) {
      return window.tizenSupportedKeys
    }

    const inputDevice = tizenCore.tvinputdevice
    const supportedKeys = inputDevice.getSupportedKeys().map((key) => ({ ...key } as DeviceKey))
    inputDevice.registerKeyBatch([
      'ColorF0Red',
      'ColorF1Green',
      'MediaPlay',
      'MediaPause',
      'MediaPlayPause',
      'MediaRewind',
      'MediaFastForward',
      '0',
      '1',
      '2',
      '3',
      '4',
      '5',
      '6',
      '7',
      '8',
      '9',
    ])
    window.tizenSupportedKeys.push(...supportedKeys)
    return window.tizenSupportedKeys
  } catch (_) {
    return window?.tizenSupportedKeys ?? defaultSupportedKeys
  }
}
export const getTizenSupportedKeys = (): DeviceKey[] => {
  if (typeof window === 'undefined') {
    return defaultSupportedKeys
  }
  if (typeof window?.tizenSupportedKeys === 'undefined') {
    registerTizenKeys()
    return defaultSupportedKeys
  }
  return window.tizenSupportedKeys
}
