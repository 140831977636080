import { UpdateApp } from '@grandstand-web/bally-web-shared/src/services/config/Config'
import {
  ConfigServiceContext,
  useContextUnconditionally,
} from '@grandstand-web/bally-web-shared/src/services/config/ConfigService'
import { getWindowDeviceInfo } from '@grandstand-web/bally-web-shared/src/utils/getWindowDeviceInfo'
import { Logger } from '@grandstand-web/bally-web-shared/src/utils/logger'
import { compareVersions } from 'compare-versions'
import { useLayoutEffect, useState } from 'react'
export interface ForceUpdate extends UpdateApp {}
const logger = Logger.of('useForceUpdate')
export const useForceUpdate = () => {
  const deviceInfo = getWindowDeviceInfo()
  const { currentConfig } = useContextUnconditionally(ConfigServiceContext)
  const [forceUpdate, setForceUpdate] = useState<ForceUpdate | null>(null)

  // check if the app needs to be updated and set the forceUpdate state
  useLayoutEffect(() => {
    const isXbox = deviceInfo.platform === 'tv_xboxone'
    const isTizen = deviceInfo.platform === 'tv_samsung'
    if (!isXbox && !isTizen) {
      return
    }
    const key = isXbox ? 'xbox' : 'tizen'
    const update = currentConfig.API.update?.[key]
    if (!update || !update.enabled) {
      setForceUpdate(null)
      return
    }

    const requiredVersion = update.required_version

    const getNextForceUpdate = (curVersion: string, reqVersion: string): ForceUpdate | null => {
      // compare semver versions. Ensure that it's only 3 numbers because xbox likes to add a 4th number
      if (!isValidVersion(curVersion) || !isValidVersion(reqVersion)) {
        logger.warn('Invalid curVersion or reqVersion', { curVersion, reqVersion })
        return null
      }
      try {
        const cur = curVersion.split('.').slice(0, 3).map(Number).join('.')
        const req = reqVersion.split('.').slice(0, 3).map(Number).join('.')
        if (compareVersions(req, cur) === 1) {
          return update as ForceUpdate
        }
      } catch (error) {
        logger.error('Error comparing versions', { error, curVersion, reqVersion })
        return null
      }
      return null
    }

    setForceUpdate(getNextForceUpdate(deviceInfo.nativeVersion ?? '', requiredVersion))
  }, [currentConfig, deviceInfo.nativeVersion, deviceInfo.platform])
  return forceUpdate
}

function isValidVersion(version: string): boolean {
  // check that version only has numbers and dots
  return /^[0-9.]+$/.test(version)
}
